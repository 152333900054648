'use client';

import { Suspense, useEffect, useState } from 'react';

import { useSuspenseQuery } from '@tanstack/react-query';
import { motion, AnimatePresence } from 'framer-motion';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { usePathname } from 'next/navigation';

import { IS_DEV } from '@/constants';
import { pointOptions } from '@/query-factory/point';
import { useAuthStore } from '@/stores/AuthStore';
import { SUB_DOMAIN, useDeviceInfoStore } from '@/stores/DeviceStore';
import { useGlobalStore } from '@/stores/GlobalStore';

import CoinImage from '../_assets/coin.png';

import { GuestContent } from './GuestContent';
import { MemberContent } from './MemberContent';

const HomeBottomContainer = dynamic(() => import('./HomeBottomContainer'), {
  ssr: false,
});

const HomeBottomDrawerContent = () => {
  const { data } = useSuspenseQuery(pointOptions.pointBudget());
  const isLogin = useAuthStore((state) => state.isLogin);

  const point = isLogin ? data.weeklyRemainingPoint : data.weeklyBudget;

  const defaultContents = [
    <motion.h4
      key={0}
      animate={{ opacity: 1, y: 0 }}
      className="typo-body4 font-700 text-Gray-white gap-4 flex-center"
      exit={{ opacity: 0, y: -20 }}
      initial={{ opacity: 0, y: 20 }}
    >
      <Image alt="코인" height={22} src={CoinImage} width={22} />
      <span className="text-[#C49B5F]">2,000P</span>
      부터 출금되는 웰포인트!
    </motion.h4>,
    <motion.h4
      key={1}
      animate={{ opacity: 1, y: 0 }}
      className="typo-body4 font-700 text-Gray-white gap-4 flex-center"
      exit={{ opacity: 0, y: -20 }}
      initial={{ opacity: 0, y: 20 }}
    >
      <Image alt="코인" height={22} src={CoinImage} width={22} />
      매일매일
      <span className="text-[#C49B5F]">웰포인트</span>챙겨보세요
    </motion.h4>,
  ];

  const pointContents = [
    <motion.h4
      key={3}
      animate={{ opacity: 1, y: 0 }}
      className="typo-body4 font-700 text-Gray-white gap-4 flex justify-between items-center w-full"
      exit={{ opacity: 0, y: -20 }}
      initial={{ opacity: 0, y: 20 }}
    >
      <div className="flex-center text-[#C49B5F] gap-4">
        <Image alt="코인" height={22} src={CoinImage} width={22} />
        이번 주 준비된 웰포인트
      </div>
      <strong className="typo-body2 font-700">{point.toLocaleString()}P</strong>
    </motion.h4>,
  ];

  const contents = [...defaultContents, ...(point > 0 ? pointContents : [])];

  const contentCount = contents.length;

  const [sectionIndex, setSectionIndex] = useState(0);

  useEffect(() => {
    const isLastOne = sectionIndex === contentCount - 1;

    if (isLastOne) {
      const timer = setTimeout(() => {
        setSectionIndex(0);
      }, 5_000);

      return () => clearTimeout(timer);
    } else {
      const timer = setTimeout(() => {
        setSectionIndex((prev) => prev + 1);
      }, 2_000);

      return () => clearTimeout(timer);
    }
  }, [contentCount, sectionIndex]);

  const isAppDrawerOpened = useDeviceInfoStore(
    (state) => state.appModal.isOpened,
  );

  const isNoDrawer = isLogin === undefined || isAppDrawerOpened;

  if (isNoDrawer) return null;

  return (
    <HomeBottomContainer
      closedContent={
        <AnimatePresence mode="wait">{contents[sectionIndex]}</AnimatePresence>
      }
    >
      {isLogin ? <MemberContent /> : <GuestContent />}
    </HomeBottomContainer>
  );
};

export const HomeBottomDrawer = () => {
  const [isDisplayed, setIsDisplayed] = useState(false);
  const setIsHomeBottomDrawerOpened = useGlobalStore(
    (state) => state.setIsHomeBottomDrawerOpened,
  );
  const subDomain = useDeviceInfoStore((state) => state.subDomain);
  const pathname = usePathname();

  useEffect(
    () => () => {
      setIsDisplayed(false);
      setIsHomeBottomDrawerOpened(false);
    },
    [pathname, setIsHomeBottomDrawerOpened],
  );

  useEffect(() => {
    if (!IS_DEV && (!subDomain || subDomain === SUB_DOMAIN.KB_PAY)) return;

    let timeout: NodeJS.Timeout;

    setIsDisplayed(true);

    const resizeHandler = () => {
      if (timeout) clearTimeout(timeout);
      setIsDisplayed(false);
      setIsHomeBottomDrawerOpened(false);

      timeout = setTimeout(() => {
        setIsDisplayed(true);
      }, 100);
    };

    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [setIsHomeBottomDrawerOpened, subDomain]);

  if (!isDisplayed) return null;

  return (
    <Suspense>
      <HomeBottomDrawerContent />
    </Suspense>
  );
};
