import { useInView } from 'react-intersection-observer';

import { Button } from '@common/components';
import { Lottie } from '@common/components/atom/Lottie';
import Image from 'next/image';

import { CustomLink } from '@/components/atom/CustomLink';
import { ROUTES } from '@/constants';
import { useAuthStore } from '@/stores/AuthStore';

import {
  REWARD_RENDING_STATE,
  useRewardRendingState,
} from '../../_hooks/useRewardRendingState';
import AllClearImage from '../_assets/card/all.png';
import NoClearImage from '../_assets/card/no.png';
import OneLeftImage from '../_assets/card/one.png';
import SomeClearImage from '../_assets/card/some.png';

interface RewardRendingCardProps {
  isDisplayed?: boolean;
}

export const RewardRendingCard = ({
  isDisplayed = false,
}: RewardRendingCardProps) => {
  const nickname = useAuthStore((state) => state.myInfo?.nickName);

  const { state, benefitCount, leftMission } = useRewardRendingState();

  const inView = useInView();

  switch (state) {
    case REWARD_RENDING_STATE.LOADING:
      return null;

    case REWARD_RENDING_STATE.GUEST:
      return (
        <div className="px-17 py-32 rounded-large bg-[#343537] flex-center flex-col gap-8">
          <Image alt="비회원" height={32} src={AllClearImage} width={32} />
          <p className="typo-body3 font-600 text-Gray-400 text-center">
            오늘의 웰포인트를
            <br />
            받아보세요!
          </p>
          <p className="text-Gray-600 typo-caption1 font-400 mb-12">
            {50 <= benefitCount
              ? `지금 ${benefitCount.toLocaleString()}명의 사용자가 혜택을 받았어요.`
              : '친구 초대할 때마다 나도 친구도 500P 적립'}
          </p>
          <CustomLink
            className="bg-B-500 h-48 min-w-245 rounded-medium px-8 flex-center text-Gray-300 typo-body3 font-700"
            href={ROUTES.LOGIN.pathname}
          >
            친구 초대하고 500P 받기
          </CustomLink>
        </div>
      );

    case REWARD_RENDING_STATE.INITIAL:
      return (
        <div className="relative bg-[#343537] rounded-medium px-17 py-32 overflow-hidden flex-center flex-col gap-12 w-full">
          <Image alt="0" height={32} src={NoClearImage} width={32} />
          <div className="text-Gray-400 font-600 typo-body3 w-full text-center">
            <p className="text-nowrap w-full overflow-hidden flex justify-center">
              <span className="truncate">{nickname}</span>님,
            </p>
            <p>오늘의 지원금을 놓치고 있어요!</p>
          </div>
          <p className="typo-caption1 font-400 text-Gray-600 py-0">
            지금 {benefitCount.toLocaleString()}명의 사용자가 혜택을 받았어요.
          </p>
          <Button asChild className="max-w-245 w-full mt-12" dimensions="H48">
            <CustomLink href={ROUTES.ATTENDANCE_DAILY_CHECK.pathname}>
              시작하기
            </CustomLink>
          </Button>
        </div>
      );

    case REWARD_RENDING_STATE.DO_SOMETHING:
    case REWARD_RENDING_STATE.LEFT_ONE: {
      if (!leftMission) return null;
      const item = {
        isAttendance: {
          title: '출석체크',
          href: ROUTES.ATTENDANCE_DAILY_CHECK.pathname,
        },
        isInvite: {
          title: '친구 초대',
          href: ROUTES.POINTS_INVITE.pathname,
        },
        isSaveFilter: {
          title: '맞춤 정보 입력',
          href: ROUTES.POLICY_FILTER.pathname({
            situation: 'join',
          }),
        },
        isWrite: {
          title: '글·댓글 작성',
          href: ROUTES.COMMUNITY.pathname,
        },
      }[leftMission];

      const isLastOne = state === REWARD_RENDING_STATE.LEFT_ONE;

      return (
        <div className="relative bg-[#343537] rounded-medium px-17 py-32 overflow-hidden flex-center flex-col gap-12 w-full">
          <Image
            alt="1"
            height={32}
            src={isLastOne ? OneLeftImage : SomeClearImage}
            width={32}
          />
          <div className="text-Gray-400 font-600 typo-body3 w-full text-center">
            <p className="text-nowrap w-full overflow-hidden flex justify-center">
              <span className="truncate">{nickname}</span>님,
            </p>
            {isLastOne ? (
              <p>
                {item.title}하면 오늘의 웰포인트
                <br />
                모두 받을 수 있어요!
              </p>
            ) : (
              <p>오늘 받을 수 있는 웰포인트가 남아있어요!</p>
            )}
          </div>
          <Button asChild className="max-w-245 w-full mt-12" dimensions="H48">
            <CustomLink href={item.href}>{item.title}하기</CustomLink>
          </Button>
        </div>
      );
    }

    case REWARD_RENDING_STATE.COMPLETED: {
      return (
        <div
          ref={inView.ref}
          className="relative bg-[#343537] typo-body3 rounded-medium px-17 py-32 overflow-hidden flex-center flex-col text-Gray-400 gap-12 w-full font-600"
        >
          <Image
            alt="웰포인트 지급 완료"
            height={32}
            src={AllClearImage}
            width={32}
          />
          모든 웰포인트를 지급 받았어요!
          {isDisplayed && inView.inView ? (
            <Lottie
              className="absolute -top-40"
              loop={false}
              src="/assets/jsons/congratulation.json"
            />
          ) : null}
          <p className="typo-caption1 font-400 text-nowrap w-full overflow-hidden flex justify-center">
            <span className="truncate">{nickname}</span>님 대단해요!
          </p>
        </div>
      );
    }
  }
};
