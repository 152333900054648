import CountUp from 'react-countup';
import { Transition } from 'react-transition-group';

import { Button } from '@common/components';
import { SvgCheck } from '@common/icon';
import { cn } from '@common/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import Image from 'next/image';

import { EventPopover } from '@/app/_components/EventPopover';
import { ROUTES } from '@/constants';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { pointOptions } from '@/query-factory/point';
import { useGlobalStore } from '@/stores/GlobalStore';

import CalendarFillImage from '../_assets/calendar-fill.png';
import CalendarImage from '../_assets/calendar.png';
import CoinImage from '../_assets/coin.png';
import LetterFillImage from '../_assets/letter-fill.png';
import LetterImage from '../_assets/letter.png';
import PaperFillImage from '../_assets/paper-fill.png';
import PaperImage from '../_assets/paper.png';
import PencilFillImage from '../_assets/pencil-fill.png';
import PencilImage from '../_assets/pencil.png';
import SvgStar from '../_assets/start.svg';

import { GuideSlider } from './GuideSlider';
import { PointProgressBar } from './PointProgressBar';
import { RewardRendingCard } from './RewardRendingCard';

export const MemberContent = () => {
  const isOpened = useGlobalStore((state) => state.isHomeBottomDrawerOpened);
  const pointBudgetQuery = useSuspenseQuery(pointOptions.pointBudget());
  const { data: myPointBudget } = useSuspenseQuery(
    pointOptions.myPointBudget(),
  );

  const { pointMission, eventCombo } = myPointBudget;

  const baseQuestItems = [
    {
      title: '출석체크',
      icon: CalendarImage,
      fillIcon: CalendarFillImage,
      isActive: pointMission.isAttendance,
      pointText: '행운도전',
      href: ROUTES.ATTENDANCE_DAILY_CHECK.pathname,
    },
    {
      title: '친구초대',
      icon: LetterImage,
      fillIcon: LetterFillImage,
      isActive: pointMission.isInvite,
      pointText: '500P 받기',
      href: ROUTES.POINTS_INVITE.pathname,
    },
    {
      title: '글·댓글 작성',
      icon: PencilImage,
      fillIcon: PencilFillImage,
      isActive: pointMission.isWrite,
      pointText: '최대 150P',
      href: ROUTES.COMMUNITY.pathname,
    },
  ] as const;

  const filterQuestItem = {
    title: '맞춤정보 등록',
    icon: PaperImage,
    fillIcon: PaperFillImage,
    isActive: pointMission.isSaveFilter,
    pointText: '500P 받기',
    href: ROUTES.POLICY_FILTER.pathname({
      situation: 'join',
    }),
  } as const;

  const questItems = pointMission.isSaveFilter
    ? baseQuestItems
    : [...baseQuestItems, filterQuestItem];

  const router = useCustomRouter();

  return (
    <Transition
      in={isOpened}
      timeout={{
        enter: 1000,
        exit: 300,
      }}
    >
      {(state) => {
        return (
          <div className="flex flex-col px-20 pb-40">
            <header className="flex justify-between items-center">
              <div className="flex flex-col typo-h5 text-Gray-500 font-500">
                <span className="text-[#C49B5F] typo-body4 font-700 mb-4 break-keep">
                  {`이번 주 준비된 웰포인트 `}
                  {pointBudgetQuery.data.weeklyRemainingPoint <= 0
                    ? '모두 드렸어요'
                    : null}
                </span>
                {pointBudgetQuery.data.weeklyRemainingPoint > 0 ? (
                  <CountUp
                    className="text-Gray-white font-700 typo-h4 mb-4"
                    delay={500}
                    end={
                      state === 'exited'
                        ? 0
                        : pointBudgetQuery.data.weeklyRemainingPoint
                    }
                    suffix="P"
                  />
                ) : (
                  <p className="text-Gray-white typo-body1 font-500">
                    다음 주 월요일,
                    <br />
                    <strong className="typo-h5 font-800">
                      새 포인트로 만나요!
                    </strong>
                  </p>
                )}
              </div>
              <Image alt="코인" height={90} src={CoinImage} width={90} />
            </header>
            <PointProgressBar className="mb-20" isDisplayed={isOpened} />
            <section className="text-Gray-white px-20 py-18 bg-[#343537] rounded-large flex-col gap-8 mb-20">
              <div className="flex items-center justify-between">
                <h4 className="typo-body4 font-500 mb-4">오늘 모은 웰포인트</h4>
                {eventCombo ? (
                  <div className="flex-center gap-2 px-12 py-4 size-fit bg-[#212223] rounded-pill text-[#C49B5F] typo-caption1 font-600">
                    {eventCombo === 1
                      ? `혜택 ${eventCombo}일차`
                      : `${eventCombo}일 연속 혜택 받는중`}
                    <SvgStar className="size-12" />
                  </div>
                ) : null}
              </div>
              <div className="font-800 typo-h4 flex gap-8 items-center">
                {myPointBudget.todayAccumulatedPoint.toLocaleString()}
                <div className="text-[10px] bg-[#C49B5F] rounded-pill size-16 flex-center pt-1 font-700">
                  P
                </div>
              </div>
            </section>
            <ul
              className={cn('grid grid-cols-3 gap-12 mb-20', {
                'grid-cols-2': questItems.length === 4,
              })}
            >
              {questItems.map(
                ({ title, icon, fillIcon, pointText, isActive, href }) => {
                  return (
                    <li
                      key={title}
                      className="relative bg-[#343537] rounded-medium px-17 py-20 flex-1 flex-center flex-col gap-8"
                    >
                      {title === '친구초대' ? (
                        <EventPopover className="-top-12" variant="dark" />
                      ) : null}
                      <div
                        className={cn(
                          'relative bg-Gray-900 size-50 flex-center rounded-pill transition-all duration-300 delay-300',
                          {
                            'bg-Gray-black border-B-500 border-2':
                              isActive && isOpened,
                          },
                        )}
                      >
                        <Image
                          alt={title}
                          height={28}
                          src={isActive ? fillIcon : icon}
                          width={28}
                        />
                        {isActive && isOpened ? (
                          <div className="bg-B-500 absolute size-14 rounded-pill flex-center -right-4 bottom-2 animate-jump-in duration-300 delay-500">
                            <SvgCheck
                              className="size-8 text-Gray-white stroke-Gray-white"
                              strokeWidth={2}
                            />
                          </div>
                        ) : null}
                      </div>
                      <strong className="typo-body4 font-600 text-Gray-white">
                        {title}
                      </strong>
                      <Button
                        className="disabled:bg-[#28292b] disabled:text-Gray-300 text-nowrap"
                        dimensions="H32"
                        onClick={(e) => {
                          e.preventDefault();
                          router.push(href);
                        }}
                      >
                        {isActive ? '완료' : pointText}
                      </Button>
                    </li>
                  );
                },
              )}
            </ul>
            <RewardRendingCard isDisplayed={isOpened} />
            <GuideSlider className="mt-20" />
          </div>
        );
      }}
    </Transition>
  );
};
