import type { Swiper as SwiperType } from 'swiper';
import type { SwiperProps } from 'swiper/react';

import { useRef, useState } from 'react';

import { Button } from '@common/components';
import { cn } from '@common/utils';
import { motion, AnimatePresence } from 'framer-motion';
import Image from 'next/image';
import { Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { EventPopover } from '@/app/_components/EventPopover';

import BookIcon from '../_assets/book.png';
import Guide0Image from '../_assets/guide/0.png';
import Guide1Image from '../_assets/guide/1.png';
import Guide2Image from '../_assets/guide/2.png';
import Guide3Image from '../_assets/guide/3.png';

const SWIPER_PROPS = {
  grabCursor: true,
  spaceBetween: 4,
  mousewheel: true,
  modules: [Mousewheel],
  slidesPerView: 'auto',
} satisfies SwiperProps;

interface GuideSliderProps {
  className?: string;
}

const variants = {
  enter: (direction: number) => ({
    x: direction > 0 ? 300 : -300,
    opacity: 0,
  }),
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => ({
    x: direction > 0 ? -300 : 300,
    opacity: 0,
  }),
};

export const GuideSlider = ({ className }: GuideSliderProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0);

  const handleClick = (index: number) => {
    setDirection(index > currentIndex ? 1 : -1);
    setCurrentIndex(index);
  };

  const [height, setHeight] = useState<number>();

  const ref = useRef<HTMLDivElement>(null);
  const swiper = useRef<SwiperType | null>(null);

  return (
    <section
      className={cn(
        'bg-[#343537] rounded-large overflow-hidden pb-40',
        className,
      )}
    >
      <div className="bg-[#28282A] py-20 px-16 flex items-center gap-12">
        <Image alt="book" height={20} src={BookIcon} width={20} />
        <h4 className="text-Gray-200 typo-body3 gap-10 mr-auto">
          포인트 적립, 이렇게 시작하세요!
        </h4>
      </div>
      <Swiper
        {...SWIPER_PROPS}
        className="mt-16"
        onSwiper={(swiperRef) => {
          swiper.current = swiperRef;
        }}
      >
        {['출석체크', '친구초대', '글·댓글 작성', '맞춤정보 등록'].map(
          (title, index) => (
            <SwiperSlide
              key={index}
              className="w-fit first:ml-18 last:mr-18"
              onClick={() => swiper.current?.slideTo(index - 1)}
            >
              <Button
                className={cn('w-fit', {
                  'text-Gray-300': currentIndex === index,
                })}
                dimensions="H48"
                theme="sub-text"
                onClick={() => handleClick(index)}
              >
                {title}
              </Button>
            </SwiperSlide>
          ),
        )}
      </Swiper>
      <AnimatePresence custom={direction} mode="wait">
        <motion.div
          key={currentIndex}
          ref={ref}
          animate="center"
          className="relative"
          custom={direction}
          exit="exit"
          initial="enter"
          style={{ height: height }}
          transition={{
            x: { type: 'tween', stiffness: 500, damping: 30 },
            opacity: { duration: 0.2 },
          }}
          variants={variants}
          onAnimationStart={(e) => {
            if (e === 'exit') {
              setHeight(ref.current?.offsetHeight ?? 0);
            }
          }}
        >
          {!currentIndex ? (
            <div className="absolute top-0 left-18 text-Gray-600 bg-Gray-900 typo-caption2 rounded-xsmall px-4 py-2">
              예시 이미지
            </div>
          ) : null}
          {currentIndex === 1 ? (
            <div className="flex justify-center">
              <EventPopover className="top-24" variant="dark" />
            </div>
          ) : null}
          <Image
            alt={`guide-${currentIndex}`}
            className="px-18 pt-28 mb-18 mx-auto"
            height={980}
            src={
              [Guide0Image, Guide1Image, Guide2Image, Guide3Image][currentIndex]
            }
            width={425}
          />
          {
            [
              <div key={0} className="flex-center flex-col">
                <p className="typo-body4 text-Gray-600 font-400">
                  출석 체크하고 랜덤 포인트 적립
                </p>
                <p className="typo-body3 text-Gray-400 font-600">
                  일주일 연속 출석 시{' '}
                  <span className="text-[#C49B5F]">추가 포인트</span>까지
                </p>
              </div>,
              <div key={1} className="flex-center flex-col">
                <p className="typo-body4 text-Gray-600 font-400">
                  초대받은 친구가 회원 가입하면
                </p>
                <p className="typo-body3 text-Gray-400 font-600">
                  친구도 나도 <span className="text-[#C49B5F]">500P</span> 적립
                </p>
              </div>,
              <div key={2} className="flex-center flex-col">
                <p className="typo-body4 text-Gray-600 font-400">
                  정책경험 질문하고 공유하면
                </p>
                <p className="typo-body3 text-Gray-400 font-600">
                  참여율에 따라 최대{' '}
                  <span className="text-[#C49B5F]">1,000P</span> 적립
                </p>
              </div>,
              <div key={3} className="flex-center flex-col">
                <p className="typo-body4 text-Gray-600 font-400">
                  나에게 꼭 맞는 정책 추천을 위해
                </p>
                <p className="typo-body3 text-Gray-400 font-600">
                  정보 입력하면 <span className="text-[#C49B5F]">500P</span>{' '}
                  적립
                </p>
              </div>,
            ][currentIndex]
          }
        </motion.div>
      </AnimatePresence>
    </section>
  );
};
