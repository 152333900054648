'use client';

import { cn } from '@common/utils';
import dayjs from 'dayjs';

interface EventPopoverProps {
  variant?: 'white' | 'dark';
  className?: string;
}

export const EventPopover = ({
  variant = 'white',
  className,
}: EventPopoverProps) => {
  const isEventTime =
    dayjs().isAfter(dayjs('2024-12-09 16:00:00')) &&
    dayjs().isBefore(dayjs('2024-12-18 23:59:59'));

  if (!isEventTime) {
    return null;
  }

  return (
    <div
      className={cn(
        'absolute top-0 flex items-center px-12 py-4 w-[max-content] rounded-pill animate-bounce animate-duration-1000',
        className,
        {
          'bg-B-50': variant === 'white',
          'bg-Gray-black': variant === 'dark',
        },
      )}
    >
      <img
        alt="X 2배 EVENT"
        className="size-16"
        src="/assets/pngs/party_popper.png"
      />
      <p
        className={cn('flex gap-4 typo-caption1 font-700 whitespace-nowrap', {
          'bg-gradient-to-r from-[#7733FF] via-[#5E76FC] to-[#32ADEA] text-transparent bg-clip-text':
            variant === 'white',
          'text-Gray-white': variant === 'dark',
        })}
      >
        X 2배
        <span
          className={cn({
            'bg-gradient-to-r from-[#7733FF] via-[#5E76FC] to-[#32ADEA] text-transparent bg-clip-text':
              variant === 'dark',
          })}
        >
          EVENT
        </span>
      </p>
      <div
        className={cn(
          'absolute -bottom-8 left-1/2 -translate-x-1/2 w-0 h-0 border-l-8 border-l-transparent border-r-8 border-r-transparent border-t-8',
          {
            'border-t-B-50': variant === 'white',
            'border-t-Gray-black': variant === 'dark',
          },
        )}
      />
    </div>
  );
};
