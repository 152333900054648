import { useQuery } from '@tanstack/react-query';

import { pointOptions } from '@/query-factory/point';
import { useAuthStore } from '@/stores/AuthStore';

export enum REWARD_RENDING_STATE {
  LOADING = 0,
  GUEST = 1,
  INITIAL = 2,
  DO_SOMETHING = 3,
  LEFT_ONE = 4,
  COMPLETED = 5,
}

export const useRewardRendingState = () => {
  const isLogin = useAuthStore((state) => state.isLogin);

  const pointBudgetQuery = useQuery(pointOptions.pointBudget());

  const myPointBudgetQuery = useQuery({
    ...pointOptions.myPointBudget(),
    enabled: isLogin,
  });

  const { data: pointBudget } = pointBudgetQuery;
  const { data: myPointBudget } = myPointBudgetQuery;

  const result = {
    state: REWARD_RENDING_STATE.LOADING,
    benefitCount: pointBudget?.benefitCount ?? 0,
    leftMission: myPointBudget?.leftMission?.[0],
  };

  if (isLogin === undefined) return result;

  if (!pointBudget) return result;

  if (!isLogin)
    return {
      ...result,
      state: REWARD_RENDING_STATE.GUEST,
    };

  if (!myPointBudget) return result;

  if (!myPointBudget.leftMission.length)
    return {
      ...result,
      state: REWARD_RENDING_STATE.COMPLETED,
    };

  const [leftMission] = myPointBudget.leftMission;

  if (!myPointBudget.completedMission.length)
    return {
      ...result,
      state: REWARD_RENDING_STATE.INITIAL,
      leftMission,
    };

  if (myPointBudget.leftMission.length === 1)
    return {
      ...result,
      state: REWARD_RENDING_STATE.LEFT_ONE,
      leftMission,
    };

  return {
    ...result,
    state: REWARD_RENDING_STATE.DO_SOMETHING,
    leftMission,
  };
};
