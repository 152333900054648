'use client';

import React from 'react';

import { Button, CertificationBadge } from '@common/components';

import { TERM_CATEGORY } from '@/api';
import Instagram from '@/assets/svgs/instagram.svg';
import Logo from '@/assets/svgs/logo.svg';
import NaverBlog from '@/assets/svgs/naver_blog.svg';
import Youtube from '@/assets/svgs/youtube.svg';
import { CustomLink } from '@/components/atom/CustomLink';
import { ROUTES } from '@/constants';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useDeviceInfoStore } from '@/stores/DeviceStore';
import { useGlobalStore } from '@/stores/GlobalStore';

import styles from './MainFooter.module.scss';

export const MainFooter = () => {
  const appVersion = useDeviceInfoStore((state) => state.appVersion);
  const setTermsDrawerType = useGlobalStore(
    (state) => state.setTermsDrawerType,
  );

  let devCount = 0;

  const router = useCustomRouter();

  return (
    <footer className={`${styles['footer']} bg-white`}>
      <Logo className={styles.logo} />
      <div className="flex justify-between items-start py-24">
        <ul className={styles['page-list']}>
          <li>
            <CustomLink href={ROUTES.NOTICE}>공지사항</CustomLink>
          </li>
          <li>
            <CustomLink href={ROUTES.INQUIRY_LIST}>1:1 문의</CustomLink>
          </li>
        </ul>
        <CertificationBadge />
      </div>
      <ul className={styles['info-list']}>
        <li
          onClick={() => {
            devCount++;
            if (devCount === 10) {
              router.push(ROUTES.TEST.pathname);
            }
          }}
        >
          주식회사 웰로
          <div />
          대표 김유리안나
        </li>
        <li className="pointer-events-none">
          <span>사업자등록번호:</span> 705-86-02126
        </li>
        <li>
          <address>
            서울특별시 관악구 남부순환로 1933, 8층 (봉천동) <div />
          </address>
        </li>
        <li>
          <a className="underline" href="mailto:cs@wello.info">
            cs@wello.info
          </a>
          <div />
          <a href="tel:02-889-9369">02-889-9369</a>
        </li>
      </ul>
      <ul className={styles['link-list']}>
        <li>
          <a
            href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=7058602126"
            rel="noreferrer"
            target="_blank"
          >
            사업자정보확인
          </a>
        </li>
        <li>
          <button
            type="button"
            onClick={() => setTermsDrawerType(TERM_CATEGORY.SERVICE_TERMS)}
          >
            이용약관
          </button>
        </li>
        <li>
          <button
            className={styles['privacy-policy']}
            type="button"
            onClick={() => setTermsDrawerType(TERM_CATEGORY.PRIVACY_POLICY)}
          >
            개인정보처리방침
          </button>
        </li>
        <li>
          <button
            type="button"
            onClick={() => setTermsDrawerType(TERM_CATEGORY.MARKETING_TERMS)}
          >
            마케팅이용동의
          </button>
        </li>
      </ul>
      <article className="typo-caption2 mt-14">
        <section className="mb-8">
          (주)웰로에서 제공하는 정보는 신뢰할 만한 자료 및 정보로부터 얻어진
          것이나 그 정확성이나 완전성을 보장할 수 없으며, 시간이 경과함에 따라
          변경될 수 있습니다. 따라서 정보의 오류, 누락에 대하여 (주)웰로 또는
          (주)웰로에 자료를 제공하는 기관에서는 그 결과에 대해 법적인 책임을
          지지 않습니다. (주)웰로가 소유/운영/관리하는 웹사이트 및 앱의 이벤트
          정보 및 모든 정보 UI, 콘텐츠 등에 대한 무단 복제, 배포, 발송 또는
          전송, 스크래핑 등의 행위는 관련 법령에 의하여 엄격히 금지 됩니다.
        </section>
        <Button
          asChild
          className="typo-caption2 rounded-xsmall w-full font-600"
          dimensions="H26"
          theme="sub-line"
        >
          <CustomLink href={ROUTES.CONTENT_COMPLIANCE_INFORMATION.pathname}>
            콘텐츠산업 진흥법에 따른 표시
          </CustomLink>
        </Button>
      </article>
      <nav className={styles['footer-social']}>
        <a
          aria-label="인스타그램에서 웰로 팔로우하기"
          className={styles['social-instagram']}
          href="https://www.instagram.com/wello_welfarehello"
          rel="noreferrer"
          target="_blank"
        >
          <Instagram className={styles['social-icon']} />
        </a>
        <a
          aria-label="유튜브에서 웰로 구독하기"
          className={styles['social-youtube']}
          href="https://www.youtube.com/@wello_welfarehello"
          rel="noreferrer"
          target="_blank"
        >
          <Youtube className={styles['social-icon']} />
        </a>
        <a
          aria-label="네이버 블로그에서 웰로 방문하기"
          className={styles['social-blog']}
          href="https://blog.naver.com/wello_official"
          rel="noreferrer"
          target="_blank"
        >
          <NaverBlog className={styles['social-icon']} />
        </a>
      </nav>

      <strong className={styles.inc}>
        ⓒ Wello Inc.
        <div className={styles.version}>{appVersion}</div>
      </strong>
    </footer>
  );
};
