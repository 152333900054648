import { Transition } from 'react-transition-group';

import { cn } from '@common/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import Image from 'next/image';

import { pointOptions } from '@/query-factory/point';

import ProgressPointImage from '../_assets/progress-point.png';
import SparklesImage from '../_assets/sparkles.png';
import WarningImage from '../_assets/warning.png';

import styles from './PointProgressBar.module.scss';

interface PointProgressBarProps {
  isDisplayed: boolean;
  className?: string;
}

export const PointProgressBar = ({
  isDisplayed,
  className,
}: PointProgressBarProps) => {
  const { data } = useSuspenseQuery(pointOptions.pointBudget());

  const percentage =
    data.weeklyAccumulatedPointPercentage < 10
      ? 10
      : data.weeklyAccumulatedPointPercentage;

  const isNearFull = 90 < percentage;

  return (
    <section className={className}>
      <Transition
        in={isDisplayed}
        timeout={{
          enter: 1000,
          exit: 300,
        }}
      >
        {(state) => {
          return (
            <div className="bg-[#1F3C5A8F]/55 h-6 w-full mt-50 mb-8 rounded-pill relative">
              {(() => {
                switch (isDisplayed) {
                  case data.weeklyRemainingPoint <= 0:
                    return (
                      <div className="absolute right-0 bottom-20 flex items-center gap-2 typo-caption1 font-700 text-B-400">
                        <Image
                          alt="모두소진"
                          height={16}
                          src={SparklesImage}
                          width={16}
                        />
                        모두 소진
                      </div>
                    );
                  case isNearFull:
                    return (
                      <div className="absolute right-0 bottom-20 flex items-center gap-2 typo-caption1 font-700 text-B-400">
                        <Image
                          alt="소진임박"
                          className="animate-pulse animate-thrice animate-duration-500"
                          height={16}
                          src={WarningImage}
                          width={16}
                        />
                        소진임박!
                      </div>
                    );
                  default:
                    return null;
                }
              })()}
              <motion.div
                animate={{
                  width:
                    isDisplayed || state === 'exiting'
                      ? `${percentage}%`
                      : '0%',
                }}
                className={cn(
                  styles['progress-bar'],
                  'relative h-full rounded-pill',
                )}
                initial={{ width: '0%' }}
                transition={{ duration: 1, ease: 'easeOut' }}
              >
                {!isDisplayed || isNearFull ? null : (
                  <div
                    className={cn(
                      'absolute right-0 -top-14 w-8 h-0',
                      'border-l-4 border-Gray-white/15 border-l-transparent border-r-4 border-r-transparent border-t-[3px] border-t-current',
                      {
                        'animate-fade-up animate-duration-300 animate-delay-700':
                          state === 'entering',
                        [styles['bounce-animation']]: state === 'entered',
                      },
                    )}
                  >
                    <div className="relative w-full">
                      <div className="absolute bg-Gray-white/15 -top-30 h-27 left-1/2 -translate-x-1/2 text-nowrap typo-caption1 font-700 text-Gray-white flex-center px-8 rounded-small">
                        {data.weeklyAccumulatedPointText}P 소진
                      </div>
                    </div>
                  </div>
                )}
                <Image
                  alt="progress-high"
                  className="absolute -right-16 top-1/2 -translate-y-1/2 animate-pulse animate-duration-[2s] animate-ease-in-out"
                  draggable={false}
                  height={28}
                  src={ProgressPointImage}
                  width={36}
                />
              </motion.div>
            </div>
          );
        }}
      </Transition>
      <p className="text-end text-Gray-white font-600 typo-body4">
        {data.weeklyBudgetText}P
      </p>
    </section>
  );
};
