import Image from 'next/image';

import { EventPopover } from '@/app/_components/EventPopover';
import { CustomLink } from '@/components/atom/CustomLink';
import { ROUTES } from '@/constants';
import { useGlobalStore } from '@/stores/GlobalStore';

import CalendarFillImage from '../_assets/calendar-fill.png';
import CoinImage from '../_assets/coin.png';
import LetterFillImage from '../_assets/letter-fill.png';
import PaperFillImage from '../_assets/paper-fill.png';
import PencilFillImage from '../_assets/pencil-fill.png';

import { GuideSlider } from './GuideSlider';
import { PointProgressBar } from './PointProgressBar';
import { RewardRendingCard } from './RewardRendingCard';
export const GuestContent = () => {
  const isOpened = useGlobalStore((state) => state.isHomeBottomDrawerOpened);

  const contentsItemData = [
    {
      title: '랜덤 포인트 받기',
      buttonText: '행운 도전',
      description: '매일 출석체크',
      image: CalendarFillImage,
      href: ROUTES.ATTENDANCE_DAILY_CHECK.pathname,
    },
    {
      title: '친구 초대하기',
      buttonText: '500P 받기',
      description: '초대할수록 혜택이 쌓인다!',
      image: LetterFillImage,
      href: ROUTES.POINTS_INVITE.pathname,
    },
    {
      title: '글·댓글 작성하기',
      buttonText: '최대 150P ',
      description: '연속 작성하면 혜택도 두 배!',
      image: PencilFillImage,
      href: ROUTES.LOGIN.withSearchParams({
        searchParams: {
          redirect: ROUTES.COMMUNITY.pathname,
        },
      }),
    },
    {
      title: '맞춤정보 등록하기',
      buttonText: '500P 받기',
      description: '정책 추천 위해',
      image: PaperFillImage,
      href: ROUTES.LOGIN.pathname,
    },
  ];

  return (
    <div className="flex flex-col px-20 gap-20 pb-52">
      <header className="flex-center flex-col mb-24">
        <Image
          alt="코인"
          className="mb-12"
          height={90}
          src={CoinImage}
          width={90}
        />
        <p className="text-[#C49B5F] typo-body3 font-600 mb-8">
          2,000포인트 이상 모으면
        </p>
        <strong className="typo-h4 text-center text-Gray-white">
          계좌로 출금 가능한
          <br />
          웰포인트
        </strong>
      </header>
      <PointProgressBar isDisplayed={isOpened} />
      <RewardRendingCard />
      <ul className="flex flex-col gap-24 bg-[#343537] px-20 py-24 rounded-large">
        {contentsItemData.map((item) => (
          <li key={item.description} className="relative">
            <CustomLink
              className="flex justify-between items-center"
              href={item.href}
            >
              <div className="flex items-center gap-8">
                <div className="size-64 bg-Gray-900 rounded-large flex-center">
                  <Image
                    alt={item.description}
                    height={32}
                    src={item.image}
                    width={32}
                  />
                </div>
                <div className="font-600">
                  <p className="text-Gray-600 typo-caption1">
                    {item.description}
                  </p>
                  <p className="typo-body3 text-Gray-300">{item.title}</p>
                </div>
              </div>
              <div className="relative bg-Gray-black typo-caption1 font-700 text-Gray-300 py-7 px-6 rounded-small max-w-68 w-full flex-center h-fit">
                {item.title === '친구 초대하기' ? (
                  <EventPopover className="-top-40" variant="dark" />
                ) : null}
                {item.buttonText}
              </div>
            </CustomLink>
          </li>
        ))}
      </ul>
      <GuideSlider />
    </div>
  );
};
