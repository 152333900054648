'use client';

import { useInfiniteQuery } from '@tanstack/react-query';

import { WELLO_APP_PUSH_PARENT_CATEGORY_TYPE } from '@/api';
import { PageNavigation } from '@/components/client';
import { useCheckWelloApp } from '@/hooks/useCheckWelloApp';
import { useStatusBarColor } from '@/hooks/useStatusBarColor';
import { appPushQueryOptions } from '@/query-factory/app-push';
import { useAuthStore } from '@/stores/AuthStore';
import { STATUS_COLOR, useDeviceInfoStore } from '@/stores/DeviceStore';

export const HomeNavigation = () => {
  const isLogin = useAuthStore((state) => state.isLogin);
  const { isWelloApp } = useCheckWelloApp();
  const fcmToken = useDeviceInfoStore(
    (state) => state.deviceTokenData?.fcm_token ?? '',
  );

  const policyAppPushQuery = useInfiniteQuery(
    appPushQueryOptions.list({
      fcmToken,
      isLogin,
      category: WELLO_APP_PUSH_PARENT_CATEGORY_TYPE.POLICY,
    }),
  );
  const communityAppPushQuery = useInfiniteQuery(
    appPushQueryOptions.list({
      fcmToken,
      isLogin,
      category: WELLO_APP_PUSH_PARENT_CATEGORY_TYPE.COMMUNITY,
    }),
  );

  const serviceAppPushQuery = useInfiniteQuery(
    appPushQueryOptions.list({
      fcmToken,
      isLogin,
      category: WELLO_APP_PUSH_PARENT_CATEGORY_TYPE.SERVICE,
    }),
  );

  const hasUnreadNotifications = (query: typeof policyAppPushQuery) =>
    query.data?.pages?.[0]?.context?.contents?.some((item) => !item.read_yn);

  const showNotificationDot =
    hasUnreadNotifications(policyAppPushQuery) ||
    hasUnreadNotifications(communityAppPushQuery) ||
    hasUnreadNotifications(serviceAppPushQuery);

  useStatusBarColor({
    color: STATUS_COLOR.HOME,
  });

  return (
    <PageNavigation
      logo
      searchButton
      backButton={false}
      bellButton={isWelloApp}
      className="bg-Gray-100"
      notification={showNotificationDot}
    />
  );
};
