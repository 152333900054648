import { createUuid } from '@common/utils';
import {
  infiniteQueryOptions,
  keepPreviousData,
  queryOptions,
} from '@tanstack/react-query';

import { AUTH_API, POINT_ACTIVITY, UNAUTH_API } from '@/api';

export const pointOptionsKeys = {
  /** 본인 인증된 사용자 정보 */
  memberAccount: createUuid(),
  /** 포인트 정보 */
  point: createUuid(),
  /** 출석 내역 조회 */
  pointAttendance: createUuid(),
  /** 초대 수 */
  invitedMemberCount: createUuid(),
  /** 포인트 입출금 내역 리스트 */
  pointHistoryInfiniteList: createUuid(),
  /** 은행 리스트 */
  bankList: createUuid(),
  /** 포인트 예산 조회 */
  pointBudget: createUuid(),
  /** 포인트 시세 조회 */
  pointPrice: createUuid(),
  /** 내 포인트 조회 */
  myPointBudget: createUuid(),
};

export const pointOptions = {
  //* 본인 인증된 사용자 정보
  memberAccount: () =>
    queryOptions({
      queryFn: () => AUTH_API.getMemberAccount(),
      queryKey: [pointOptionsKeys.memberAccount],
      placeholderData: keepPreviousData,
      select: ({ context }) => {
        return {
          accountNumber: context?.account_number,
          accountOwner: context?.account_owner,
          bankId: context?.bank_id,
          birthDate: context?.birth_date,
          memberAccountId: context?.member_account_id,
        };
      },
    }),
  //* point 정보, 특히 totalPoints
  point: () =>
    queryOptions({
      queryFn: () => AUTH_API.getDetailPointBalance(),
      queryKey: [pointOptionsKeys.pointAttendance],
      placeholderData: keepPreviousData,
      select: ({ context }) => {
        return {
          totalPoints: context?.total_points,
          debitablePoints: context?.debitable_points,
          memberOauthId: context?.member_oauth_id,
        };
      },
      //* 매번 요청 최신화
      staleTime: 0,
      gcTime: 0,
    }),
  //* 은행 리스트
  bankList: () =>
    queryOptions({
      queryFn: () => UNAUTH_API.getBankList(),
      queryKey: [pointOptionsKeys.bankList],
      placeholderData: keepPreviousData,
      select: ({ context }) => {
        return {
          contents: context?.contents,
        };
      },
    }),
  // * 출석 내역 조회
  pointAttendance: () =>
    queryOptions({
      queryFn: () => AUTH_API.getListPointAttendance(),
      queryKey: [pointOptionsKeys.pointAttendance],
      placeholderData: keepPreviousData,
      select: ({ context }) => {
        return {
          attendanceList: context?.attendance_list.map((item) => ({
            checked: item.attendance_yn,
            date: item.date,
          })),
          count: context?.attendance_count,
          month: context?.month,
          point: context?.total_points,
        };
      },
    }),
  //* 초대 수
  invitedMemberCount: () =>
    queryOptions({
      queryFn: () => AUTH_API.getInvitedMemberCount(),
      queryKey: [pointOptionsKeys.invitedMemberCount],
      placeholderData: keepPreviousData,
      select: ({ context }) => {
        return {
          invitedMemberCount: context?.invited_member_count,
        };
      },
    }),
  //* 포인트 입출금 내역 리스트
  pointHistoryInfiniteList: (
    params: Pick<
      Parameters<typeof AUTH_API.getPointHistoryList>[number],
      'activityType'
    >,
  ) =>
    infiniteQueryOptions({
      queryKey: [
        pointOptionsKeys.pointHistoryInfiniteList,
        params.activityType,
      ],
      queryFn: ({ pageParam }) =>
        AUTH_API.getPointHistoryList({
          activityType: params.activityType,
          page: pageParam,
          size: 50,
        }),
      getNextPageParam: (lastPage, allPages) => {
        // list length가 0이 아니면 다음 page 요청
        if (lastPage.context && lastPage.context.contents.length > 0) {
          return allPages.length + 1;
        }

        return null; // 나머지 조건에는 종료
      },
      initialPageParam: 1,
      staleTime: 0,
      gcTime: 0,
    }),
  //* 포인트 예산 조회
  pointBudget: () =>
    queryOptions({
      queryFn: () => UNAUTH_API.getPointBudget(),
      queryKey: [pointOptionsKeys.pointBudget],
      placeholderData: keepPreviousData,
      staleTime: 0,
      select: ({ context }) => {
        const weeklyBudget = context?.budget_point ?? 0;
        const weeklyAccumulatedPoint = context?.accumulated_point ?? 0;
        const benefitCount = context?.benefit_count ?? 0;
        const weeklyAccumulatedPointPercentage =
          (weeklyAccumulatedPoint / weeklyBudget) * 100;
        const weeklyRemainingPoint = context?.remain_point ?? 0;

        let weeklyAccumulatedPointText = '';

        if (10_000 <= weeklyAccumulatedPoint) {
          weeklyAccumulatedPointText = `${Math.floor(weeklyAccumulatedPoint / 10_000).toLocaleString()}만`;
        } else {
          weeklyAccumulatedPointText = `${weeklyAccumulatedPoint.toLocaleString()}`;
        }

        let weeklyBudgetText = '';

        if (10_000 <= weeklyBudget) {
          weeklyBudgetText = `${(weeklyBudget / 10_000).toLocaleString()}만`;
        } else {
          weeklyBudgetText = `${weeklyBudget.toLocaleString()}`;
        }

        return {
          weeklyBudget,
          weeklyAccumulatedPointPercentage,
          weeklyRemainingPoint,
          weeklyAccumulatedPointText,
          weeklyBudgetText,
          benefitCount,
        };
      },
    }),
  //* 포인트 시세 조회
  pointPrice: () =>
    queryOptions({
      queryFn: () => UNAUTH_API.getPointPrice(),
      queryKey: [pointOptionsKeys.pointPrice],
      placeholderData: keepPreviousData,
      staleTime: Infinity,
      gcTime: Infinity,
      select: ({ context }) => {
        const pointEarning = context?.point_earning;

        if (!pointEarning) return;

        const activity = Object.values(POINT_ACTIVITY).reduce(
          (acc, cur) => {
            acc[cur] = pointEarning[cur];

            return acc;
          },
          {} as Record<POINT_ACTIVITY, number | undefined>,
        );

        return activity;
      },
    }),
  myPointBudget: () =>
    queryOptions({
      queryFn: () => AUTH_API.getPointBudget(),
      queryKey: [pointOptionsKeys.myPointBudget],
      select: ({ context }) => {
        const pointMission = {
          isSaveFilter: context?.save_filter_yn ?? false,
          isAttendance: context?.today_attendance_yn ?? false,
          isInvite: context?.today_invite_yn ?? false,
          isWrite: context?.today_write_yn ?? false,
        };

        const completedMission: (keyof typeof pointMission)[] = [];
        const leftMission: (keyof typeof pointMission)[] = [];

        Object.entries(pointMission).forEach(([key, value]) => {
          const missionKey = key as keyof typeof pointMission;
          if (value) {
            completedMission.push(missionKey);
          } else {
            leftMission.push(missionKey);
          }
        });

        return {
          eventCombo: context?.event_count ?? 0,
          todayAccumulatedPoint: context?.today_accumulated_point ?? 0,
          completedMission,
          leftMission,
          pointMission,
        };
      },
      staleTime: 0,
      placeholderData: keepPreviousData,
    }),
};
