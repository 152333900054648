import { createUuid } from '@common/utils';
import { infiniteQueryOptions } from '@tanstack/react-query';

import {
  WELLO_APP_PUSH_PARENT_CATEGORY_TYPE,
  AUTH_API,
  UNAUTH_API,
} from '@/api';

const appPushQueryOptionsKeys = {
  list: createUuid(),
};

interface AppPushListOptionsProps {
  fcmToken: string;
  isLogin?: boolean;
  category?: WELLO_APP_PUSH_PARENT_CATEGORY_TYPE;
}

export const appPushQueryOptions = {
  list: ({
    fcmToken,
    isLogin,
    category = WELLO_APP_PUSH_PARENT_CATEGORY_TYPE.POLICY,
  }: AppPushListOptionsProps) => {
    const INITIAL_PAGE = 1;
    const PAGE_SIZE = 20;

    return infiniteQueryOptions({
      queryKey: [
        appPushQueryOptionsKeys.list,
        fcmToken,
        INITIAL_PAGE,
        PAGE_SIZE,
        isLogin,
        category,
      ],
      queryFn: ({ pageParam = INITIAL_PAGE }) => {
        const params = {
          fcmToken,
          page: pageParam,
          size: PAGE_SIZE,
          welloAppPushParentCategoryTypeCd: category,
        };

        return isLogin
          ? AUTH_API.getAppPush(params)
          : UNAUTH_API.getAppPushList(params);
      },
      enabled: isLogin !== undefined,
      getNextPageParam: (lastPage) => {
        if (lastPage.context?.total_count && lastPage.context.page) {
          const nextPage = lastPage.context.page + 1;
          const maxPage = Math.ceil(lastPage.context.total_count / PAGE_SIZE);

          return nextPage <= maxPage ? nextPage : undefined;
        }
      },

      initialPageParam: INITIAL_PAGE,
    });
  },
};
